.field-name-field-cimkefelho,
.field-name-field-galeria-video-kategoria,
.field-name-field-galeria-kep-kategoria,
.field-name-field-galeria-hang-kategoria {
  margin: 40px 0;

  .links {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .links li {
    margin-right: 10px;
  }

  .links li:last-child {
    margin-right: 0;
  }

  .links li, .links a {
    float: left;
    line-height: rem-calc(24);
    position: relative;
    font-size: rem-calc(12);
  }

  .links a {
    padding: rem-calc(10);
    background: #b0b0b0;
    color: $white;
    text-decoration: none;
    transition: all 0.5s ease-in-out;
  }

  .links a:hover {
    background: transparent;
    color: $default-red;
    transition: all 0.5s ease-in-out;
  }

  .links a:hover:before {
    border-color: transparent #00793d transparent transparent;
  }

}

@media #{$small-only} {
  .field-name-field-cimkefelho,
  .field-name-field-galeria-video-kategoria,
  .field-name-field-galeria-kep-kategoria,
  .field-name-field-galeria-hang-kategoria {
    margin: 20px 0;

    .links {
      li, a {
        float: none;
        line-height: 2.3rem;
        text-align: center;
        width: 100%;
      }
    }
  }
}

.taxonomy-term {

  ul {
    margin: 0;
    padding: 0;
    right: rem-calc(24);
    list-style: none;

    li {
      float: left;
      line-height: rem-calc(24);
      font-size: rem-calc(12);
      width: auto;
      padding: 0;
    }

    a {
      float: left;
      line-height: rem-calc(24);
      //position: relative;
      font-size: rem-calc(15);
      margin-left: rem-calc(5);
      padding: rem-calc(5);
      background: $default-green;
      color: $white;
      text-decoration: none;
      border-radius: $global-radius;
    }

    a:before {
      content: "#";
      margin-right: 5px;
      padding-left: rem-calc(7);
    }

    a:hover {
      background: #00793d;
    }

    a:hover:before {
      border-color: transparent #00793d transparent transparent;
    }

  }
}