// Non-modular or client styles for .l-footer region.

.gray-bg {
  background-color: #dddbdc;
}

.row.l-footer-columns {
  padding-top: rem-calc(25);
  min-height: rem-calc(360);
  height: auto;

  section.block {

    .block-title {
      font-size: rem-calc(23);
    }

    p {
      font-family: $font-family-centurygothic-bold;
      font-size: rem-calc(14);
    }

    p.no-event {
      font-family: $font-family-centurygothic;
      font-size: rem-calc(14);
    }

    .footer-first {
      a {
        text-align: center;
        display: block;
      }
    }
  }

  .footer-third {
    text-align: justify;
  }
}

.row.l-footer-columns section.block-block-1 {

  p {
    margin-bottom: rem-calc(20);
  }
}

.node-type-aloldal .gray-bg,
.node-type-page .gray-bg {
  margin-top: rem-calc(30);
}

@media #{$large-up} {
  section.row.l-footer-columns {
    overflow: visible;

    img {
      margin-left: -100px;
    }
  }
}

.footer-secondhalf.large-6.columns {
  background: url("../images/k_terkep.png") no-repeat center;
  height: 366px;
  margin-top: rem-calc(20);

  @media #{$large-up} {
    background: none;
    height: auto;
    margin-top: 0;
  }
}

@media #{$large-only} {
  .footer-first {
    padding: 0;

    P {
      margin-bottom: 0;
    }

    a {
      text-align: left;
    }
  }

  .footer-second {
    padding: 0 .9375rem 0 0;

    p {
      margin-bottom: rem-calc(20);
    }
  }
}

footer.l-footer.panel.row {
  display: none;
}


// Kattintható térkép

.footer-secondhalf .block-block-2 a {
  color: $default-text-color;
  font-size: rem-calc(14);
  display: block;
  height: rem-calc(290);
}
