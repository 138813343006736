/*

A library of transitions for revealing the loaded images
(Heavily) Inspired by http://tympanus.net/codrops/2013/07/02/loading-effects-for-grid-items-with-css-animations/

*/

.effect-parent {
  -webkit-perspective: 1300px;
  -moz-perspective: 1300px;
  perspective: 1300px;
}


/* EFFECT 1 */
.effect-1 {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 50% -300px;
  -moz-transform-origin: 50% 50% -300px;
  transform-origin: 50% 50% -300px;
  -webkit-transform: rotateX(-180deg);
  -moz-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
  -webkit-animation: fly ease-in-out forwards;
  -moz-animation: fly ease-in-out forwards;
  animation: fly ease-in-out forwards;
}
@-webkit-keyframes fly {
  100% { -webkit-transform: rotateX(0deg); opacity: 1; -webkit-transform-origin:50% 50% 0; }
}

@-moz-keyframes fly {
  100% { -moz-transform: rotateX(0deg); opacity: 1; -moz-transform-origin:50% 50% 0; }
}

@keyframes fly {
  100% { transform: rotateX(0deg); opacity: 1; transform-origin:50% 50% 0; }
}



/* EFFECT 2 */
.effect-2 {
  -webkit-transform: translateY(200px);
  -moz-transform: translateY(200px);
  transform: translateY(200px);
  -webkit-animation: moveUp ease forwards;
  -moz-animation: moveUp ease forwards;
  animation: moveUp ease forwards;
}

@-webkit-keyframes moveUp {
  to { -webkit-transform: translateY(0); opacity: 1; }
}

@-moz-keyframes moveUp {
  to { -moz-transform: translateY(0); opacity: 1; }
}

@keyframes moveUp {
  to { transform: translateY(0); opacity: 1; }
}


/* EFFECT 3 */
.effect-3 {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: translateZ(400px) translateY(300px) rotateX(-90deg);
  -moz-transform: translateZ(400px) translateY(300px) rotateX(-90deg);
  transform: translateZ(400px) translateY(300px) rotateX(-90deg);
  -webkit-animation: fallPerspective ease-in-out forwards;
  -moz-animation: fallPerspective ease-in-out forwards;
  animation: fallPerspective ease-in-out forwards;
}

@-webkit-keyframes fallPerspective {
  100% { -webkit-transform: translateZ(0px) translateY(0px) rotateX(0deg); opacity: 1; }
}

@-moz-keyframes fallPerspective {
  100% { -moz-transform: translateZ(0px) translateY(0px) rotateX(0deg); opacity: 1; }
}

@keyframes fallPerspective {
  100% { transform: translateZ(0px) translateY(0px) rotateX(0deg); opacity: 1; }
}


/* EFFECT 4 */
.effect-4 {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  -webkit-transform: rotateX(-80deg);
  -moz-transform: rotateX(-80deg);
  transform: rotateX(-80deg);
  -webkit-animation: flip ease-in-out forwards;
  -moz-animation: flip ease-in-out forwards;
  animation: flip ease-in-out forwards;
}

@-webkit-keyframes flip {
  100% { -webkit-transform: rotateX(0deg); opacity: 1; }
}

@-moz-keyframes flip {
  100% { -moz-transform: rotateX(0deg); opacity: 1; }
}

@keyframes flip {
  100% { transform: rotateX(0deg); opacity: 1; }
}


/* EFFECT 5 */
.effect-5 {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-animation: moveUp ease-in-out forwards;
  -moz-animation: moveUp ease-in-out forwards;
  animation: moveUp ease-in-out forwards;
}


/* EFFECT 6 */
.effect-6 {

  -webkit-transform:  scale(0.638) translate(-179px);
  -moz-transform:     scale(0.638) translate(-179px);
  transform:          scale(0.638) translate(-179px);

  -webkit-animation: moveUp ease-in-out forwards;
  -moz-animation: moveUp ease-in-out forwards;
  animation: moveUp ease-in-out forwards;
}

/* Universal durations */
.effect-duration-1{
  -webkit-animation-duration: .4s;
  -moz-animation-duration:    .4s;
  animation-duration:         .4s;
}
.effect-duration-2{
  -webkit-animation-duration: .5s;
  -moz-animation-duration:    .5s;
  animation-duration:         .5s;
}
.effect-duration-3{
  -webkit-animation-duration: .6s;
  -moz-animation-duration:    .6s;
  animation-duration:         .6s;
}
.effect-duration-4{
  -webkit-animation-duration: .7s;
  -moz-animation-duration:    .7s;
  animation-duration:         .7s;
}
.effect-duration-5{
  -webkit-animation-duration: .8s;
  -moz-animation-duration:    .8s;
  animation-duration:         .8s;
}
.effect-duration-6{
  -webkit-animation-duration: .9s;
  -moz-animation-duration:    .9s;
  animation-duration:         .9s;
}
.effect-duration-7{
  -webkit-animation-duration: .95s;
  -moz-animation-duration:    .95s;
  animation-duration:         .95s;
}
.effect-duration-8{
  -webkit-animation-duration: 1s;
  -moz-animation-duration:    1s;
  animation-duration:         1s;
}
.effect-duration-9{
  -webkit-animation-duration: 1.05s;
  -moz-animation-duration:    1.05s;
  animation-duration:         1.05s;
}
.effect-duration-10{
  -webkit-animation-duration: 1.1s;
  -moz-animation-duration:    1.1s;
  animation-duration:         1.1s;
}