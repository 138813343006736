$color-red: #ee4035;
$color-white: #fff;
$title-pad: 0.25em;
$title-lineheight: $title-pad + 1;
$color-gray: #818181;

.padded-multi-line {
  display: inline;
  background: $color-white;
  padding: 5px 0;
  box-shadow: 10px 0 0 #fff, -10px 0 0 #fff;
}

.news hr {
  margin-left: rem-calc(-5);
  margin-right: rem-calc(-5);
}

.news-author img {
  max-width: 50px;
  border: 3px solid $default-green;
  border-radius: 1000px;
}

.news-group {
  margin-top: rem-calc(20);
}

article.news {
  background-color: $color-white;
  position: relative;
  border: rem-calc(1) solid $border-gray;
  margin-right: rem-calc(20);
  margin-bottom: rem-calc(40);
  -webkit-box-shadow: 0 0 20px 0 rgba(50, 50, 50, 0.5);
  -moz-box-shadow:    0 0 20px 0 rgba(50, 50, 50, 0.5);
  box-shadow:         0 0 20px 0 rgba(50, 50, 50, 0.5);
}

article.news:last-child {
  margin-right: 0;
  margin-bottom: rem-calc(40);
}

.news-data-left h5 {
  color: inherit;
  -ms-transform: rotate(-0.001deg);
  margin-top: -30px;
}

.news-data-left h5 a {
  line-height: 32.8px;
  font-size: 22px;
  color: #000;
  transition: all 0.5s ease-in-out;
}

.news-data-left h5 a:hover {
  color: $default-red;
  transition: all 0.5s ease-in-out;
}

.news-text {
  color: $color-gray;
}

.news-text .user-logo-area {
  width: 25%;
  float: right;
  margin: -10px 0 20px 20px;
}

.news-text .user-logo-area img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.news-author {
  text-align: right;
  float: none !important;
}

.news-time {
  margin-top: 10px;
  color: $color-gray;
  font-size: rem-calc(16);
  font-family: $font-family-centurygothic-bold;
  text-align: center;
}

.news-content .news-outer {
  padding: 0 20px 20px 20px;
}

.news-content img {
  display: block;
  width: 100%;
}

a.btn-more {
  font-family: $font-family-centurygothic-bold;
  font-size: rem-calc(13) !important;
  -ms-transform: rotate(-0.001deg);

}

.news .news-button {
  display: inline-block;
  height: auto;
  position: absolute;
  top: 20px;
  right: 20px;
}

.news-all-button {
  margin: 0 0 0 .9375rem;

  a {
    font-size: 1.4rem;
    color: $color-red;
    font-weight: bold;
    opacity: 1.0;
    transition: all 0.5s ease-in-out;
  }

  a:hover {
    opacity: 0.6;
    transition: all 0.5s ease-in-out;
  }
}

.line-left {
  text-align: right;
  border-bottom: 1px solid #acacac;
  line-height: 0;
  padding: 0;

  span {
    background: #f2f2f2;
    padding: 0 15px 0 30px;
  }
}

.line-right {
  text-align: left;
  border-bottom: 1px solid #acacac;
  line-height: 0;
  padding: 0;

  span {
    background: #f2f2f2;
    padding: 0 30px 0 0;
  }
}

.most-recent-title {
  color: #c9c9c9;
  margin: 50px 0;
}

/*
* === Összes hír ===
*/

.view-osszes-hir {
  margin-bottom: rem-calc(20);

  a {
    color: $default-text-color;
    -ms-transform: rotate(-0.001deg);

  }

  .views-field-title {
    a {
      opacity: 1.0;
      font-size: rem-calc(18);
      color: $default-red;
      transition: opacity 0.5s ease-in-out;
    }

    a:hover {
      opacity: 0.6;
      transition: opacity 0.5s ease-in-out;
    }
  }

  .views-field-created {
    /*margin: rem-calc(10) rem-calc(10) 0 0;*/
    text-align: left;
    display: block;
    font-style: italic;
  }

  .views-field-title-1 {
    display: inline-block;
    text-align: left;
    margin-top: rem-calc(10);
  }

  img {
    margin-bottom: rem-calc(16);
    padding: rem-calc(5);
    border: 1px solid $border-gray;

    @media #{$small-only} {
      display: block;
    }
    @media #{$medium-up} {
      max-width: 302px;
      float: left;
      margin-right: rem-calc(20);
    }
  }
}

/*
* === Social bar ===
*/
.social_bar_bg {
  height: rem-calc(40);
  background: rgb(235, 235, 235) none repeat scroll 0% 0%;
  position: absolute;
  width: 100%;
  border-bottom: 2px solid red;
  display: inline;
  top: 0;
  left: 0;
  z-index: -10;
}

.social_bar {
  text-align: right;
  //  float: right;
}

.block-views-social-media-block h2 {
  font-size: rem-calc(16);
  font-family: $font-family-centurygothic-bold;
  display: inline;
  margin-bottom: 0;
}

ul.social {
  list-style-type: none;
  display: inline-block;
  margin: 0;
}

ul.social li {
  display: inline;
  margin-left: rem-calc(10);
}

.social li:last-of-type {
  display: inline;
  margin-right: rem-calc(15);
}

ul.social li a {
  font-size: rem-calc(25);
  color: $default-text-color;
}

.social {
  li a:hover {
    .fa-facebook {
      color: $default-green;
    }
    .fa-rss {
      color: $default-green;
    }
    .fa-youtube {
      color: $default-green;
    }
  }
}

/*
* === RÓLUNK MONDTÁK ===
*/

.rolunk_mondtak_velemeny {
  border: 1px solid $border-gray;
  position: relative;
}

.rolunk_mondtak_velemeny:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 33px;
  top: 166px;
  border: 16px solid;
  border-color: $border-gray transparent transparent $border-gray;
}

.rolunk_mondtak_velemeny:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 34px;
  top: 165px;
  border: 15px solid;
  border-color: #fff transparent transparent #fff;
}

.rolunk_mondtak_velemeny p {
  padding: rem-calc(20);
  margin-bottom: 0;
}

.rolunk_mondtak_velemeny p:before {
  content: open-quote;
  font-size: rem-calc(24);
  line-height: 0;
  margin-right: rem-calc(5);
  vertical-align: rem-calc(-15);
}

.rolunk_mondtak_velemeny p:after {
  content: close-quote;
  font-size: rem-calc(24);
  line-height: 0;
  margin-left: rem-calc(5);
}

.rolunk_mondtak_szerzo {
  font-family: $font-family-centurygothic-bold;
  font-size: rem-calc(18);
  margin-top: rem-calc(33);
  display: inline-block;
}

.rolunk_mondtak_datum {
  font-size: rem-calc(18);
  font-family: $font-family-centurygothic-bold;
  color: #d9d9d9;
}

/*
* === SAJTÓ ===
*/

.view-sajto {

  .views-field-title-1 {
    text-align: right;
  }
}

@media #{$small-only} {
  .page-sajto,
  .page-galeria,
  .page-partnereink,
  .page-esemenynaptar {

    ul.button-group {

      li {
        float: none;
        width: 100%;
        margin-bottom: rem-calc(5);
      }
    }
  }
}

.page-sajto,
.page-galeria,
.page-partnereink,
.page-esemenynaptar {

  #page-title {
    display: none;
  }

  ul.button-group {
    margin-bottom: 40px;
    margin-top: 27px;

    li {
      border-left: 7px solid $default-red;
    }

    li.active {
      a {
        opacity: 0.4;
      }
    }

    li {
      a {
        margin: 0;
        font-size: 20px;
        background: transparent;
        padding-left: rem-calc(10);
        padding-top: 0;
        padding-bottom: 0;
      }

      a:hover {
        background: transparent;
      }
    }

  }

  .views-field-title {
    font-size: rem-calc(18);
    font-family: $font-family-centurygothic-bold;
  }

  .sajto_datum,
  .sajto_medium {
    color: $default-red;
    font-size: rem-calc(13);
    font-weight: bold;
  }

  .button-group .button {
    border: 1px solid $border-gray;
    font-size: rem-calc(20);
    font-family: $font-family-centurygothic-bold;
  }
}

.sajto_medium:before {
  content: "\00a0 - \00a0";
  display: inline-block;
}

.page-sajto-rolunk-irtak .sajto_medium:before {
  content: " ";
}

.page-sajto .field-content p {
  font-size: rem-calc(15);
}

/*
* === GALÉRIA ===
*/

.galeria-cim-szerzo {

  margin-bottom: rem-calc(20);

  .views-field-title {
    display: inline-block;
    float: left;
    margin-top: rem-calc(16);
    a {
      color: $default-green;
    }
  }
}

.page-galeria .view-galeria {
  a.colorbox img {
    border: 1px solid $border-gray;
    padding: rem-calc(5);
  }
}

.node-type-galeria-video {

  .file-video-youtube {
    display: inline-block;
    margin-bottom: rem-calc(25);

    @media#{$medium-up} {
      width: 49%;
    }

    @media#{$large-up} {
      width: 24%;
    }

  }
}

@media #{$small-only} {
  .galeria-list {
    .galeria-ul {
      li {
        iframe, audio {
          max-width: 100%;
        }
      }
    }
  }
}




