.author-tooltip {
  display: block;
  position: absolute;
  right: 81px;
  background: #00974c;
  padding: 10px 25px;
  border-radius: 7px
}
.author-tooltip .slider_full_name {
  font-size: 1.2rem
}
.author-tooltip .slider_full_name,
.author-tooltip .slider_szorvany {
  display: block;
  font-weight: 700;
  text-align: center
}
.author-tooltip:after,
.author-tooltip:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none
}
.author-tooltip:after,
.author-tooltip:before {
  border-color: rgba(0, 151, 76, 0);
  border-left-color: #00974c;
  border-width: 15px;
  margin-top: -15px
}