.node-cikk {
  .posted {

  }
}

.group-related-content-title {

  margin: 40px 0;

  h3 {
    color: $default-red;
    margin-bottom: rem-calc(20);
  }

  a {
    border-left: 4px solid $default-red;
    padding-left: 10px;
    margin-bottom: 5px;
    color: #000;
    font-size: 18px;
    transition: all 0.5s ease-in-out;
  }

  a:hover {
    color: $default-red;
    transition: all 0.5s ease-in-out;
  }
}