@media #{$small-only} {
  .button-group {
    li {
      width: 50%;

      a.small.button {
        width: 100%;
        padding: rem-calc(10);
      }
    }
  }
}

/* 2015-07-10 */
.page-partnereink .button-group > li:first-child .button, .page-galeria .button-group > li:first-child .button {
  border-left: 1px solid #f2f1f1;
  border-top-left-radius: 6px;
}
.page-partnereink ul.button-group li.active a.small.button.secondary.active,
.page-galeria ul.button-group li.active a.small.button.secondary.active {
  border-bottom: medium none;
  color: #201a1b;
  background-color: transparent;
}

.page-galeria ul.button-group li.active a.small.button.secondary {
  border-bottom: medium none;
  color: #201a1b;
  background-color: #f4f4f4;
}
.page-partnereink .button-group > li:last-child .button, .page-galeria .button-group > li:last-child .button {
  border-top-right-radius: 6px;
}
/*.page-partnereink .button-group .button,
.page-galeria .button-group .button {
  border: 1px solid #f2f1f1;
  font-size: 1rem;
  font-family: "CenturyGothic-Bold", sans-serif;
  color: #00974c;
  background-color: transparent;
}*/
.page-partnereink .button-group > li a { margin: 0; }
