/*
* === ESEMÉNYNAPTÁR ===
*/
.fullcalendar table {
  border: none;
}

.fc-header-title h2 {
  color: #b7b6bb;
}

.fc-event-default, .fc-event-default .fc-event-skin, .fc-event-default .fc-event-time, .fc-event-default a {
  border: none;
  background: transparent;
  margin-top: 5px;
}

.fc-content .fc-event-skin .fc-event-time,
.fc-content .fc-event-skin .fc-event-title {
  color: #ea512f;
  font-size: 14px;
  padding-right: 3px;
}

.fc-border-separate td {
  height: 1px;
  border: none;
}

.fc-border-separate td div:first-child {
  margin: 5px 5px 5px 0;
  background: #e6e7e9;
  height: 100%;
}
.fc-border-separate td div:first-child .fc-day-number {
  float: left;
  font-size: 20px;
  height: auto;
}

.fc-border-separate tr th {
  border: none;
  margin: 0 5px;
  padding: 10px;
  text-align: left;
  font-size: 22px;
  font-weight: 300;
  text-transform: capitalize;
  background: #6c6d71;
  color: white;
  border-right: 5px solid white;
}
.fc-border-separate tr th:last-child {
  border: none;
}

.fc-border-separate tr td:last-child div:first-child {
  margin: 5px 0 5px 0;
}

.fc-other-month { visibility: hidden; }