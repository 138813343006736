// Non-modular or client styles for asides or sidebars.

@media #{$large-up} {

  .page-user .sidebar {
    margin-top: 0;
  }

  .sidebar {

  }

  @import url(http://fonts.googleapis.com/css?family=Open+Sans:400,800);

  $background:#f9f9f9;
  $foreground:#fff;
  $foreground-light:#34495e;


  $size:25px;
  $ratio:1.2;

  $transition-time:0.3s;

  .sidebar-video-galley-thumbnail {
    display:block;
    width: 0;
    height: 0;
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: ($size*$ratio) solid $foreground;
    /*margin: ($size * 2) auto $size auto;*/
    position: absolute;
    z-index:1;
    transition: all $transition-time;
    -webkit-transition: all $transition-time;
    -moz-transition: all $transition-time;
    left: 50%;
    top: $size*3;
    margin-left: -($size/2);

    &:before {
      content:'';
      position:absolute;
      top:($size*-1.5);
      left:($size*-2.3);
      bottom:($size*-1.5);
      right:($size*-0.7);
      border-radius:50%;
      border: 1px solid $foreground;
      z-index:2;
      transition: all $transition-time;
      -webkit-transition: all $transition-time;
      -moz-transition: all $transition-time;
    }
    &:after {
      content:'';
      opacity:0;
      transition: opacity ($transition-time * 2);
      -webkit-transition: opacity ($transition-time * 2);
      -moz-transition: opacity ($transition-time * 2);
    }

    &:hover, &:focus {
      &:before {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
      }
    }

    &.active {
      border-color:transparent;
      &:after {
        content:'';
        opacity:1;
        width:($size);
        height:($size*1.6);
        background:$foreground;
        position:absolute;
        right: ($size*0.1);
        top: ($size*-0.8);
        border-left:($size*0.4) solid $foreground;
        box-shadow:inset ($size*0.6) 0 0 0 $background;
      }
    }
  }

}

.sidebar .block-views-galeriak-oldalsav-block {
  margin-top: rem-calc(30);
}

.sidebar .view-galeriak-oldalsav {

  .views-row {
    padding-bottom: rem-calc(10);
    position: relative;
  }

  .views-field-title a {
    font-family: $font-family-centurygothic-bold;
    color: $default-text-color;
    font-size: rem-calc(15);
  }

  .views-row-even {
    background-color: $border-gray;
  }
  .text-center {
    text-align: right !important;
  }
}

.sidebar {

  section {
    margin-bottom: rem-calc(20);
  }

  h2 {
    font-size: rem-calc(24);
    margin-bottom: rem-calc(20);
    padding-left: rem-calc(10);
    border-left: rem-calc(7) solid $default-red;
  }

  .item-list {
    li {

      .views-field-field-galeria-videoi {
        margin-left: -10px;
      }

      padding: rem-calc(10);

      a {
        color: $default-text-color;
        font-size: rem-calc(15);
        font-family: $font-family-centurygothic-bold;
        transition: all 0.5s ease-in-out;
      }

      a:hover {
        color: $default-red;
        transition: all 0.5s ease-in-out;
      }

    }

    li.views-row-even {
      background-color: #e7e8ea;
    }

    li.views-row-odd {
      background-color: #fff;
    }
  }

  .views-field-created {
    text-align: right;
  }
}

.styled.hidden-for-large-up {
  margin: 0 rem-calc(15);
}

.block-views-0f6e0c06a7dcbda10242a5bd4c030fbc {
  h2 {
    font-size: rem-calc(18);
    margin-left: rem-calc(15);
  }
}

.view-fogado-szervezetek-oldalsav {

  padding-left: rem-calc(15);

  .views-row {
    padding: rem-calc(15) 0;
    margin-bottom: 0;
  }

  .views-row-even {
    background-color: $border-gray;
    padding-left: rem-calc(10);
  }

  a {
    font-size: rem-calc(15);
    color: $default-text-color;
  }

}

@media #{$small-only} {
  .sidebar {
    .item-list {
      li {
        iframe {
          max-width: 100%;
        }
      }
    }
  }
}
