/********************************************//**
 *  ... Szórvány side-nav
 ***********************************************/

.block-menu-menu-szorvany-regio,
.block-menu-menu-orszagok {
  .menu {
    list-style: none;
    margin: 0 0 rem-calc(50);

    li {
      margin-bottom: rem-calc(10);
      padding: rem-calc(10);
      padding-left: rem-calc(15);
      background-color: #fff;
      transition: background-color .3s ease;
    }

    li:hover {
      background-color: scale_color($default-red, $lightness: -10%);
    }

    li:hover a {
      color: $white;
    }

    li:hover:after {
      color: $white;
    }

    li:after {
      font-family: $font-family-fontawesome;
      content: "\f0da ";
      display: inline-block;
      text-align: center;
      width: rem-calc(25);
      border-radius: $global-rounded;
      background-color: transparent;
      border: 1px solid #999999;
      float: right;
      position: absolute;
      right: rem-calc(20);
      color: #999999;
      transition: color 0.3s ease;
    }

    li.active-trail {
      background-color: $default-red;
    }

    li.active-trail:after {
      color: #fff;
    }

    li.active-trail a {
      color: #fff;
    }

    li a {
      text-transform: uppercase;
      font-family: $font-family-centurygothic-bold;
      color: $default-text-color;
      font-size: rem-calc(18);
      width: 100%;
      display: inline-block;
      transition: color 0.3s ease;
    }

  }
}
