.front {
  @media#{$small-only} {
    .breadcrumbs {
      margin-top: rem-calc(20);
    }
  }
}

.breadcrumbs {
  border: none;
  background: transparent;
  padding: .5625rem 0;
}

.breadcrumbs>* a {
  color: $default-red;
  font-size: 1rem;
  line-height: 2em;
  text-transform: none;
  font-weight: bold;
}

.breadcrumbs>*.current a {
  color: #c8c8c8;
}