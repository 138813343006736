.page-user {

  .border.rounded.left {
    float: left;
    margin-right: rem-calc(30);
    margin-bottom: rem-calc(30);
  }

  .user-picture {
    img {
      max-width: rem-calc(160);
      height: auto;
      cursor: default;
      box-shadow: 0 0 0 1px $border-gray;
    }
  }
  .field-name-field-teljes-nev {
    font-size: rem-calc(24);
    font-family: $font-family-centurygothic-bold;
  }

  .field-label {
    font-size: rem-calc(18);
  }

  .user-idezet {
    margin: rem-calc(60) 0 rem-calc(60);

  }

  .field-name-field-user-idezet {
    font-style: italic;
  }

}

.field-name-field-user-fogado-szervezet  {
  a {
    display: block;
    color: $default-red;
  }
}

.user-info-long {
  .field-label:nth-of-type(1) {
    margin-top: rem-calc(10)
  }
}

