@charset "UTF-8";
/* @file
 * This file is a custom file that loads all files. Each non-base layer
 * can be disabled.
 *
 * Do not name this file 'STARTER.scss'. This file should be named
 * THEMENAME.scss. If you used the drush command to generate this subtheme,
 * it should be named for you.
 *
 * This application file (THEMENAME.scss) is where all the partials are
 * imported.
 *
 * Theme styles are categorized using SMACSS standards. They utilize
 * categorization of styles into various categories. Those categories are the
 * following:
 *
 * - Base: CSS reset/normalize plus HTML element styling.
 * - Layout: Macro arrangement of a web page, including any grid systems.
 * - Modules: Dictate minor layout modules or reusable elements.
 * - States: Describe the appearance of a module in various states.
 * - Theme: Specific customizations for your subtheme.
 * For more information about this new Drupal css file standard, please review
 * the following:
 * - https://drupal.org/node/1887922
 * - http://smacss.com/
 */

// Make sure the charset is set appropriately

// Core
// The following imports bootstrap the theme.
// You may comment out bourbon if you don't want to use its library of mixins.
// The theme will have compilation errors if Foundation is not imported.

// Global Zurb Foundation variable overrides.
@import 'base/init';

// Comment out this import if you don't want to use normalize.
@import '../../zurb_foundation/scss/normalize';

// Comment out the imports you don't need below.
@import '../../zurb_foundation/scss/foundation/components/grid';
@import '../../zurb_foundation/scss/foundation/components/accordion';
@import '../../zurb_foundation/scss/foundation/components/alert-boxes';
@import '../../zurb_foundation/scss/foundation/components/block-grid';
@import '../../zurb_foundation/scss/foundation/components/breadcrumbs';
@import '../../zurb_foundation/scss/foundation/components/button-groups';
@import '../../zurb_foundation/scss/foundation/components/buttons';
@import '../../zurb_foundation/scss/foundation/components/clearing';
@import '../../zurb_foundation/scss/foundation/components/dropdown';
@import '../../zurb_foundation/scss/foundation/components/dropdown-buttons';
@import '../../zurb_foundation/scss/foundation/components/flex-video';
@import '../../zurb_foundation/scss/foundation/components/forms';
@import '../../zurb_foundation/scss/foundation/components/icon-bar';
@import '../../zurb_foundation/scss/foundation/components/inline-lists';
@import '../../zurb_foundation/scss/foundation/components/joyride';
@import '../../zurb_foundation/scss/foundation/components/keystrokes';
@import '../../zurb_foundation/scss/foundation/components/labels';
@import '../../zurb_foundation/scss/foundation/components/magellan';
@import '../../zurb_foundation/scss/foundation/components/orbit';
@import '../../zurb_foundation/scss/foundation/components/pagination';
@import '../../zurb_foundation/scss/foundation/components/panels';
@import '../../zurb_foundation/scss/foundation/components/pricing-tables';
@import '../../zurb_foundation/scss/foundation/components/progress-bars';
@import '../../zurb_foundation/scss/foundation/components/range-slider';
@import '../../zurb_foundation/scss/foundation/components/reveal';
@import '../../zurb_foundation/scss/foundation/components/side-nav';
@import '../../zurb_foundation/scss/foundation/components/split-buttons';
@import '../../zurb_foundation/scss/foundation/components/sub-nav';
@import '../../zurb_foundation/scss/foundation/components/switches';
@import '../../zurb_foundation/scss/foundation/components/tables';
@import '../../zurb_foundation/scss/foundation/components/tabs';
@import '../../zurb_foundation/scss/foundation/components/thumbs';
@import '../../zurb_foundation/scss/foundation/components/tooltips';
@import '../../zurb_foundation/scss/foundation/components/top-bar';
@import '../../zurb_foundation/scss/foundation/components/type';
@import '../../zurb_foundation/scss/foundation/components/offcanvas';
@import '../../zurb_foundation/scss/foundation/components/visibility';

// Comment out this import if you don't want to use Bourbon, a replacement for Compass mixins.
//@import 'bourbon';

// Import Fonts
@import '../fonts/CenturyGothic/CenturyGothic';
@import '../fonts/CenturyGothic-Bold/CenturyGothic-Bold';
@import '../fonts/FjallaOne-Regular/FjallaOne-Regular';
@import url(http://fonts.googleapis.com/css?family=PT+Sans&subset=latin,latin-ext);
@import '../fonts/fontawesome/scss/font-awesome';

// Import our mixins early so they can be used by other partials.
// Optional.
@import 'base/mixins';

// Common file is where you place common utility classes to extend or
// parametrics.
// Optional.
@import 'base/common';

// Fix for some Drupal CSS quirks (Drupalisms).
@import 'base/drupal';


// Styling for elements.
// Optional.
@import 'base/elements';

// Layout
// Each section of the document has it's own partial seperated out to improve
// developer experience. Additionally, grids can be declared in layouts as well.
@import 'layout/header';
@import 'layout/main';
@import 'layout/aside';
@import 'layout/footer';

// Pages
@import 'pages/osztondijasaink';
@import 'pages/osztondijasunk';
@import 'pages/fogado-szervezet';
@import 'pages/page-taxonomy';
@import 'pages/partnereink';
//@import 'pages/esemenynaptar';

// Modules
@import 'modules/preloader';
@import 'modules/collage-transitions';
@import 'modules/topbar';
@import 'modules/slider';
@import 'modules/author-tooltip';
@import 'modules/block';
@import 'modules/views';
@import 'modules/menu_block';
@import 'modules/agenda';
@import 'modules/tags';
@import 'modules/button';
@import 'modules/breadcrumbs';
@import "modules/form";
@import "pages/cikk";
@import "modules/osztondijasok-aside";
@import "modules/fullcalendar";
@import "modules/sharethis";
@import "modules/three-homepage-blocks";
@import "modules/cimkefelho";

// States
// @import 'states/collapse';

// Themes
// @import 'theme/button-light';

// Older IE support
// @import 'vendor/ie';
