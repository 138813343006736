.view-osztondijasaink {

  span {
    display: block;
  }

  span.muted {
    display: block;
    font-size: 18px;
    color: $default-red;
  }

  span.user_teljes_nev,
  span.user_fogado_szervezet {
    margin-bottom: 7px;

    a {
      color: #201a1b;
    }
  }

  ul {
    text-align: center;

    li {
      padding: rem-calc(10);
      border: 2px  solid transparent;
    }
  }

  .author-dropdown {
    display: none;
  }

  .osztondijas_image {
    text-align: center;
    width: rem-calc(160);
    margin: 0 auto;
    border-radius: 1000px;
    border: 5px solid #fff;
    box-shadow: 0 0 0 1px $border-gray;


    img {
      border-radius: 1000px;
      cursor: pointer;
      vertical-align: middle;
    }

  }

  .user_teljes_nev_top {
    display: block;
    margin-top: rem-calc(15);
  }
}

ul li.osztondijas_expanded {
  background: #e7e8ea;
  border: 2px solid $border-gray;
  border-bottom: 2px solid transparent;
  border-radius: $global-radius;
  -webkit-box-shadow: 1px 12px 34px -6px rgba(0,0,0,0.75);
  -moz-box-shadow: 1px 12px 34px -6px rgba(0,0,0,0.75);
  box-shadow: 1px 12px 34px -6px rgba(0,0,0,0.75);

  .osztondijas_image {
    border: 5px solid $default-red;
  }
}

ul li.osztondijas_expanded {

  position: relative;

  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  margin: 0;

  .user_teljes_nev_top {
    visibility: hidden;
  }

  .author-dropdown {
    display: block;
    background: #e7e8ea;
    position: absolute;
    left: 0;
    right: 0;
    border-radius: $global-radius;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 2px solid $border-gray;
    border-top: none;
    margin-left: -2px;
    margin-right: -2px;
    z-index: 10;
    font-family: $font-family-centurygothic-bold;
    -webkit-box-shadow: 1px 12px 34px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 12px 34px -6px rgba(0,0,0,0.75);
    box-shadow: 1px 12px 34px -6px rgba(0,0,0,0.75);

  }

  span {
    display: block;
  }

  a.btn-more {
    margin-top: rem-calc(15);
  }
}

