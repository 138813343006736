/*
* === ESEMÉNYNAPTÁR ===
*/

.containing-table {
  display: table;
  width: 100%;
  margin-bottom: 2px;

  .centre-align {
    padding: 10px;
    display: table-cell;
    text-align: left;
    vertical-align: middle;

    span {
      display: inline-block;
      vertical-align: top; /* Removes the extra white space below the baseline */
    }

    span.date-display-single {
      word-break: keep-all;
    }
  }

  .first-part { width: 25%; background: #e62b36; color: #fff; }
  .second-part { width: 65%; background: #fff; color: #000; }
  .third-part { width: 10%; background: #fff; }

}

.agenda-date.round {
  display: inline-block;
}

.agenda-caret {
  right: rem-calc(0);
}

.cal_0 a {
  color: #ebebeb;
  transition: color 0.3s ease;
}

.fa-caret-right {
  color: #999999;
  transition: all 0.3s ease-in-out;
  top: rem-calc(1);
}

.centre-align:hover {
  a {
    color: $default-green;
  }
  .fa-caret-right {
    color: #e62b36;
  }
}

.fa-stack-2x {
  color: #fff;
  border: 1px solid #999999;
  border-radius: 50%;
}

.calendar-link {
  color: $alert-color;
  float: right;
  padding-right: rem-calc(8);
  margin-top: 20px;
}

.calendar-link:after {
  font-family: $font-family-fontawesome;
  content: '\f0da';
  font-size: rem-calc(17);
  padding-left: rem-calc(5);
}

.agenda-block a.calendar-link:after:hover {
  color: scale-color($alert-color, $lightness: $button-function-factor);
}

.agenda-block a.calendar-link:hover {
  color: scale-color($alert-color, $lightness: $button-function-factor);
}
