/*
* === ÁLLAMTITKÁRI KÖSZÖNTŐ ===
*/


@media #{$medium-up} {


}

@media #{$large-up} {

}

/*
* — Államtitkári köszöntő vége —
*/


/*
* === A program ===
*/

.kepgaleria_ul a:first-of-type {
  padding-left: 0;
}

.kepgaleria_ul a:last-of-type {
  padding-right: 0;
}

.kepgaleria_ul {
  a {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: rem-calc(5);
    text-align: center;

    @media #{$medium-up} {
      width: 50%;
    }
    @media #{$large-up}{
      width: 25%;
    }

  }

  img {
    border: 1px solid $border-gray;
    padding: rem-calc(5);
    width: 100%;
  }
}

/*
* === KAPCSOLATOK ===
*/

.contact-info {
  margin-bottom: rem-calc(30);
}

.section-kapcsolat {
  .field-label {
    font-weight: $font-weight-normal;
    font-family: $font-family-centurygothic-bold;
  }
}

.webform-client-form-34 button.webform-submit.button-primary.secondary.button.radius.form-submit {
  float: right;
  background: $success-color;
  color: #fff;
  padding-top: 0.625rem;
  padding-right: 1.25rem;
  padding-bottom: 0.6875rem;
  padding-left: 1.25rem;
  font-size: rem-calc(13);
  font-family: $font-family-centurygothic-bold;
}

.block-webform h2 {
  margin-bottom: rem-calc(40);
  @media #{$small-only} {
    text-align: center;

  }
}

.block-views-kapcsolat-terkep-block {
  margin-top: rem-calc(20);
  margin-bottom: rem-calc(30);
}

#ip-geoloc-map-of-view-kapcsolat_terkep-block {
  height: 400px;
}
