.top-bar a {
  -ms-transform: rotate(-0.001deg);
}

.top-bar-section ul li {
  background-color: transparent;
}

.top-bar.expanded #main-menu {
  margin-top: rem-calc(25);
}

@media #{$small-up} {

  .top-bar-section ul li {
    position: relative;
  }

  .top-bar-section ul li.active>a {
    background-color: $white;
  }
  .top-bar-section ul li.active>a:after {
    content : "";
    position: absolute;
    left    : 0;
    bottom  : 0;
    height  : 1px;
    width   : 50%;  /* or 100px */
    border-bottom: 1px solid $default-red;
  }
}

@media #{$large-up} {

  .top-bar-section ul li.active>a:after {
    border-bottom: 0;
  }

  .top-bar-section li:not(.has-form):hover a:not(.button) {
    color: $default-text-color;
  }

  .top-bar-section li:not(.has-form) a:not(.button):hover {
    border-bottom: 3px solid $default-red;
    color: $default-text-color;
  }

  .top-bar-section li:not(.has-form) a:not(.button).active {
    border-bottom: 3px solid $default-red;
  }

  .top-bar-section li:not(.has-form) a:not(.button) {
    padding: 0 0 5px 0;
    display: inline;
  }
  .top-bar-section li:not(.has-form):after {
    display: inline-block;
    font-family: $font-family-fontawesome;
    font-size: rem-calc(5);
    content: "\f111";
    color: $default-red;
    padding-left: rem-calc(6);
    padding-right: rem-calc(6);
    vertical-align: middle;
  }

  .top-bar-section li.active:not(.has-form) a:not(.button) {
    padding: 0 0 rem-calc(5) 0;
    background: transparent;
  }

  .top-bar-section li.active:not(.has-form) a:not(.button):hover {
    padding: 0 0 rem-calc(5) 0;
    background: transparent;
  }
  .top-bar-section li:not(.has-form).last.leaf:after {
    content: " ";
  }
}

.top-bar .toggle-topbar.menu-icon a span::after {
  box-shadow: 0px 0px 0px 2px #000, 0px 7px 0px 2px #000, 0px 14px 0px 2px #000;
  margin-right: rem-calc(4);
}

.contain-to-grid .top-bar {
  margin-top: rem-calc(-62);
  margin-bottom: rem-calc(25);
  /*max-width: 80rem;*/
}

li.toggle-topbar.menu-icon {
  margin-right: rem-calc(20);
}

nav.top-bar.expanded li.toggle-topbar.menu-icon {
  border: 1px solid #ebebeb;
  border-radius: $global-radius;
  margin-right: rem-calc(20);
}

@media #{$medium-up} {
  .logged-in .header_logo {
    background: transparent;
  }
  .header_logo {
    margin-top: rem-calc(-33);
    background: url('../images/leader_logo_bg.png') no-repeat top left;

    a {
      position: relative;
      z-index: 10;
    }
  }
  nav.top-bar {
    position: static;
    margin-bottom: rem-calc(-15);
  }
}

