.homepage-three-columns {

  padding: rem-calc(50) 0 rem-calc(50) 0;

  h3 {
    border-left: 7px solid #e72c37;
    padding-left: rem-calc(10);
    margin-bottom: rem-calc(20);

    a {
      color: #000;
      transition: all 0.5s ease-in-out;
    }

    a:hover {
      color: $default-red;
      transition: all 0.5s ease-in-out;
    }
  }

  .homepage-three-column-inner {

    position: relative;

    .three-columns-text-title {
      display: block;
      text-align: center;
      margin-top: 10px;
      font-weight: bold;
      color: $default-text-color;
      transition: all 0.5s ease-in-out;
    }

    .three-columns-text-summary {
      padding: rem-calc(10) 0;
      text-align: justify;
      font-size: 14px;

      p {
        text-align: justify;
        font-size: 14px;
      }
    }

    .nextButton {
      position: absolute;
      top: rem-calc(20);
    }

    .left {
      left: rem-calc(20);
    }

    .right {
      right: rem-calc(20);
    }
  }

  .homepage-three-column-inner:hover {

    .three-columns-text-title {
      color: $default-text-color;
      transition: all 0.5s ease-in-out;
    }
  }

}