.view-partnereink,
.view-orszag {

  .button-group {
    margin-left: 1px;
  }

  .view-content .view-grouping:last-child .views-row {
    //margin: rem-calc(20);
    margin-left: 0;
  }

  .view-grouping-header {
    margin-bottom: rem-calc(30);
    margin-top: rem-calc(30);
    color: $default-text-color;
    font-size: rem-calc(23);

    a {
      color: $default-red;
    }
  }

  .view-grouping-content {
    margin-left: rem-calc(20)
  }

  .view-grouping .view-grouping-content h3 {
    display: none;
  }

  .views-row {
    //margin: rem-calc(20);
    margin-left: rem-calc(20);
  }

  .views-row-last {
    /*border-bottom: 1px solid darken($border-gray, 10);*/
  }

  .views-field-title {

    padding: rem-calc(10) 0;
   /* border-top: 1px solid darken($border-gray, 10);*/

    a {
      color: $default-text-color;
      display: inline-block;
      width: auto;
    }

    a:hover {
      color: $default-red;
      padding-left: rem-calc(15);
    }
  }
}