// Non-modular or client styles for the .l-content region.

.front h1#page-title {
  display: none;
}

main.l-main {
  margin-bottom: rem-calc(50)
}

.aloldal_bevezeto {
  font-family: $font-family-centurygothic-bold;
  margin-bottom: rem-calc(20);
  -ms-transform: rotate(-0.001deg);
}
