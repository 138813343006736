// @file
// Place your mixins here. Feel free to roll your own mixins.

///* Mixin and configurable options */
//body > div.page > main > div > div > div > div > ul > li.views-row.views-row-1.views-row-odd.views-row-first > div:nth-child(2) > div > div > ul
// { @include block-grid($per-row, $spacing, $base-style); }
//
///* This controls how many elements will be on each row of the block grid. */
///* Set this to whatever number you need, up to the max allowed in the variable */
//$per-row: false;
//
//  /* This controls how much space is between each item in the block grid */
//$spacing: $block-grid-default-spacing;
//
//  /* This controls whether or not base styles come through, set to false to leave out */
//  /* This is handy for when you create multiple block-grids and want less CSS output repetition */
//$base-style: true;

.galeria-list  ul {
  @include block-grid(1);

  @media#{$medium-up} {
    @include block-grid(2);

    li {
      position: relative;
      margin-bottom: 50px;

      ul {
        li {
          width: 100%;
          padding-bottom: 0;
          margin-bottom: 0;

          img {
            max-height: 218px;
          }
        }
      }

      audio {
        width: 100%;
      }

      .galeria-lis-inner {
        padding-bottom: 30px;
        background: #e7e8ea;

        .galeria-hanganyagok-lista {
        }

        .gallery-title-area {
          padding: rem-calc(5) rem-calc(10);
          position: relative;
          bottom: -(rem-calc(20));

          .gallery-title {
            position: absolute;
            bottom: -(rem-calc(2));
            max-width: 70%;

            a {
              line-height: 30px;
              font-size: 20px;
              font-weight: bold;
              color: #000;
              transition: all 0.5s ease-in-out;
              background: #e7e8ea;
              padding: 4px 0;
              box-shadow: 10px 0 0 #e7e8ea, -10px 0 0 #e7e8ea;
            }
          }

          .gallery-post-date {
            position: absolute;
            bottom: 0;
            right: 10px;
          }
        }
      }
    }
  }

  @media#{$large-up} {
    @include block-grid(3);

    li {
      position: relative;

      ul {
        li {
          width: 100%;

          iframe {
            width: 100% !important;
          }
        }
      }
    }
  }
}

.views-field-field-galeria-videoi ul {
  @include block-grid(1);

  @media#{$medium-up} {
    @include block-grid(2);
  }

  @media#{$large-up} {
    @include block-grid(4);
  }
}

@media#{$small-only}{
  .galeria-lis-inner {
    padding-bottom: 30px;
    background: #e7e8ea;

    .galeria-hanganyagok-lista {
    }

    .gallery-title-area {
      padding: rem-calc(5) rem-calc(10);
      position: relative;
      bottom: -(rem-calc(20));

      .gallery-title {
        position: absolute;
        bottom: -(rem-calc(2));
        max-width: 70%;

        a {
          line-height: 30px;
          font-size: 20px;
          font-weight: bold;
          color: #000;
          transition: all 0.5s ease-in-out;
          background: #e7e8ea;
          padding: 4px 0;
          box-shadow: 10px 0 0 #e7e8ea, -10px 0 0 #e7e8ea;
        }
      }

      .gallery-post-date {
        position: absolute;
        bottom: 0;
        right: 10px;
      }
    }
  }
}