.page-galeria .view-filters,
.page-partnereink .view-filters {

  @media #{$small-only} {
    margin-top: rem-calc(30);
  }

  @media #{$medium-up} {
    /*position: absolute;
    width: 50%;
    top: rem-calc(69);
    right: 15px;*/
  }

  label {

    float: none;
    text-align: right;
    padding-right: rem-calc(10);

    @media #{$medium-up} {
      /*float: right;
      padding-right: rem-calc(15);
      font-size: rem-calc(18);*/
    }

  }

  button {
    right: 1px;
  }

}