.creator-indent {
  text-align: right;
  padding-bottom: rem-calc(5);

  .fogado-full-name {
    font-family: $font-family-centurygothic-bold;
    color: $article-red;
    display: inline-block;
    /*float: left;*/
    line-height: rem-calc(60);
  }
}
.section-hirek .fogado-full-name {
  white-space: nowrap;

}

.user-pic {
  margin: 0 rem-calc(20);
}

@media #{$small-only} {
  .i-block {
    margin-left: rem-calc(15);
  }

  .section-hirek .i-block {
    margin-bottom: rem-calc(30);
  }


}


.partner-osztondijas {
  margin-bottom: rem-calc(30);
  text-align: right;

  .fogado-full-name {
    padding: 20px 0;
    display: inline-block;
  }
}
