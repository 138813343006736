// Common abstract classes to extend or parametrics are stored here.

// Example parametrics.
// ------------------------------------------------------
// %font-FONTNAME   {}
// %font-sans-serif { font-family: sans-serif; }
// %font-serif      { font-family: serif; }

html {
  font-smooth: always;
  //-ms-transform: rotate(-0.001deg);
  -webkit-font-smoothing: subpixel-antialiased;
}

body {
  background-color: $grey;
}

.row {
  /*max-width: 80rem;*/
}

.main {
  padding-top: rem-calc(20);
  padding-bottom: rem-calc(50);
  background-color: #fff;
  -webkit-box-shadow: 0 0 20px 0 rgba(50, 50, 50, 0.5);
  -moz-box-shadow:    0 0 20px 0 rgba(50, 50, 50, 0.5);
  box-shadow:         0 0 20px 0 rgba(50, 50, 50, 0.5);
}

table {
  width: 100% !important;
}

.breadcrumbs-section {
  margin-bottom: 20px;

  h3 {
    color: $default-red;
  }
}

.field-name-field-cikk-alcim,
.field-name-field_hir_alcim {
  margin-left: rem-calc(30);
  margin-bottom: rem-calc(20);
  border-left: 7px solid $default-red;
  padding-left: rem-calc(10);
  font-weight: bold;
  font-size: rem-calc(22);
}

.front .main,
.node-type-kapcsolat-aloldal .main {
  background: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
}

.h3 {
  font-size: $h3-font-size;
  font-family: $header-font-family;
  color: $default-text-color;
  text-rendering: optimizelegibility;
  margin-top: $header-top-margin;
  margin-bottom: rem-calc(8);
  line-height: 1.4;
}

h1 {
  font-size: rem-calc(28);
  color: black;
  border-left: 7px solid #e72c37;
  padding-left: rem-calc(20);
}

#page-title {
  margin-bottom: rem-calc(30);
}

hr {
  text-align: center;
}

hr.styled:after {
  content: url("../images/hr_kor.png");
  display: inline-block;
  position: relative;
  top: rem-calc(-13);
  /*background-color: #fff;*/
}

.item-list ul {
  list-style: none;

  li {
    margin-left: 0;
  }
}

ul.pagination li:hover a {
  color: #fff;
}

.muted {
  font-size: 14px;
  font-family: $font-family-centurygothic-bold;
  color: $default-gray;
}

.field-name-field-hir-fejlec-kep img,
.field-name-field-fejlec-kep img {
  margin-bottom: rem-calc(30);
}

.rounded {
  border-radius: 1000px;
  display: inline-block;
}

.border {
  border: 5px solid $white;
  box-shadow: 0 0 0 1px $border-gray;
  transition: all 0.5s ease-in-out;
}

.border:hover {
  border: 5px solid $default-red;
  transition: all 0.5s ease-in-out;
}

.border-small {
  border: 3px solid $white;
  box-shadow: 0 0 0 1px $border-gray;
}

.small-pic {
  width: rem-calc(50);
}

.i-block {
  display: inline-block;
  margin-right: rem-calc(20);
}

.balloon {
  text-align: center;
}

.bold {
  font-weight: $font-weight-bold;
}

.map-more {
  margin-top: rem-calc(10);
}

.breadcrumbs>* a:hover {
  color: darken($default-red, 5);
}

.user-picture {
  img {
    max-width: 50px;
    border-radius: 1000px;
    background-color: #fff;
    cursor: pointer;
  }
}

.views-exposed-form label {
  font-family: $font-family-centurygothic-bold;
  font-weight: $font-weight-normal;
}

.field .field-label {
  font-family: $font-family-centurygothic-bold;
  font-weight: $font-weight-normal;
}

@media #{$medium-up} {
  p {
    text-align: justify;
  }

  .field-type-text-long {
    text-align: justify;

  }
}

