.view-osztondijasaink-lista {

  margin-left: rem-calc(15);

  .views-row {
    @include clearfix();
  }

  .rounded.border.user-pic {
    float: left;
    margin-left: 0;
  }

  .views-row > span {
    line-height: rem-calc(60);
    color: $article-red;
    font-family: $font-family-centurygothic-bold;
    text-align: left;
  }

  .views-row-even {
    background-color: $border-gray;
    margin-left: rem-calc(15);
  }
}