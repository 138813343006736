.page-taxonomy {

  .node-title a {
    color: $default-text-color;
    font-weight: $font-weight-bold;
    font-size: rem-calc(16);
  }
}

.node-readmore > a {
  @include button-base();
  @include button-size($button-tny);
  @include button-style($success-color, $global-radius);
  font-weight: $font-weight-bold;
  font-size: rem-calc(13);
}