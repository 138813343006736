.flexslider {
  margin-bottom: rem-calc(20);
  border: 0;

  @media #{$small-only} {
    margin-bottom: 0;
  }
}

.slider_right {
  background-color: $article-red;
  color: #fff;
  padding: rem-calc(16);
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;


  .slider_right_inner {

    h3 {
      font-family: $font-family-centurygothic;
      color: #fff;
      /*    max-width: 75%;*/
      width: 100%;
      margin-top: rem-calc(8);
    }
    p {
      font-size: rem-calc(18);
      text-align: left;
    }

    a {
      display: inline-block;
      position: static;
      bottom: 0;
      left: 0;
      margin-top: 10px;
      width: 50px;
      height: 24px;
      background: transparent url('../images/slider_arrow.png') no-repeat center;


    }
  }
}

.flex-active-slide .slider_right {
  opacity: 1;
  right: 0;
  padding: rem-calc(16);
}

.author-tooltip {display: none;}

.user-picture {
  @include clearfix();
}

.flexslider {
  .user-picture img {
    max-width: 50px;
    border-radius: 1000px;
    border: 3px solid #fff;
    float: right;
    background-color: #fff;
    cursor: pointer;

  }
}




/* min-width 641px, medium screens */
@media #{$medium-up} {

  .flex-active-slide {
    position: relative;
  }

  .slider_right {
    position: absolute;
    right: -50%;
    top: 0;
    width: 50%;
    height: 100%;
    transition: all 1s ease-in-out 1s;
    background-color: $article-red;
  }
  .flex-active-slide .slider_right {
    right: 0;
  }

  .slider_right:after {
    right: 100%;
    bottom: 10%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: $article-red;
    border-width: rem-calc(30);
    margin-top: rem-calc(-10);
  }
}

@media #{$large-up} {
  .slider_right {
    right: -35%;
    width: 35%;
  }

  .flex-active-slide .slider_right {
    right: 0;
  }
}
